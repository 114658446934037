import { UI_MODE } from 'common/ui';

export const ROLE = {
    Admin: 'Admin',
    CarrierAdmin: 'CarrierAdmin',
    WebsiteAdmin: 'WebsiteAdmin',
    AgentAdmin: 'AgentAdmin',
    RoleAssigner: 'RoleAssigner',
    HierarchyViewer: 'HierarchyViewer',
    DashboardAdmin: 'DashboardAdmin',
    PolicyAdmin: 'PolicyAdmin',
    PolicyReviewer: 'PolicyReviewer',
    Translator: 'Translator',
    ResourceAdmin: 'ResourceAdmin',
    BetaTester: 'BetaTester',
    BackOffice: 'BackOffice',
    Contracting: 'Contracting',
    Mentor: 'Mentor',
    LeadAdmin: 'LeadAdmin',
    LeadManager: 'LeadManager',
    LeadScript: 'LeadScript',
    TrainingAdmin: 'TrainingAdmin',
    FastWillAdvisor: 'FastWillAdvisor',
};

export const TIME_PERIOD = {
    All: 'All',
    Today: 'Today',
    Yesterday: 'Yesterday',
    ThisWeek: 'ThisWeek',
    ThisMonth: 'ThisMonth',
    WTD: 'wtd',
    MTD: 'mtd',
    YTD: 'ytd',
    LastMonth: 'LastMonth',
    PriorWeek: 'prior-week',
    PriorMonth: 'prior-month',
    PriorYear: 'prior-year',
    SixMonthRolling: '6-month-rolling',
    Custom: 'custom',

    common: (includeCustom = true) => {
        const list = [TIME_PERIOD.Today, TIME_PERIOD.Yesterday, TIME_PERIOD.WTD, TIME_PERIOD.PriorWeek, TIME_PERIOD.MTD, TIME_PERIOD.PriorMonth, TIME_PERIOD.YTD];
        if (includeCustom) list.push(TIME_PERIOD.Custom);
        return list;
    },

    default: () => {
        return TIME_PERIOD.WTD;
    }
};

export const REPORT_TIME_PERIOD = {
    DTD: 'dtd',
    WTD: 'wtd',
    MTD: 'mtd',
    YTD: 'ytd',

    all: () => {
        return [
            REPORT_TIME_PERIOD.WTD,
            REPORT_TIME_PERIOD.MTD,
            REPORT_TIME_PERIOD.YTD,
        ];
    },
}

export const POLICY_DATE_TYPE = {
    SUBMITTED: 'Submitted',
    PAID: 'Paid',

    all: () => {
        return [
            POLICY_DATE_TYPE.SUBMITTED,
            POLICY_DATE_TYPE.PAID,
        ];
    },
}

export const POLICY_STATUS_GROUP = {
    Pending: 'Pending',
    ClosedNeverInForce: 'ClosedNeverInForce',
    InForce: 'InForce',
    NoLongerInForce: 'NoLongerInForce',

    all: () => {
        return [
            POLICY_STATUS_GROUP.Pending,
            POLICY_STATUS_GROUP.ClosedNeverInForce,
            POLICY_STATUS_GROUP.InForce,
            POLICY_STATUS_GROUP.NoLongerInForce,
        ];
    },
}

export const REPORT_FOR = {
    INDIVIDUAL: 'Individual',
    AGENCY: 'Agency',
    MASTER_AGENCY: 'MasterAgency',

    all: () => {
        return [
            REPORT_FOR.INDIVIDUAL,
            REPORT_FOR.AGENCY,
            REPORT_FOR.MASTER_AGENCY,
        ];
    },
}

export const METRIC_TYPE = {
    Count: 'Count',
    PolicyStatusGroup: 'PolicyStatusGroup',
    Premium: 'Premium',

    all: () => {
        return [
            METRIC_TYPE.Count,
            METRIC_TYPE.PolicyStatusGroup,
            METRIC_TYPE.Premium,
        ];
    },
}

export const AGENT_METRIC_TYPE = {
    Count: 'Count',
    PolicyStatusGroup: 'PolicyStatusGroup',
    Premium: 'Premium',
    Prospect: 'Prospect',
    RegisteredRep: 'RegisteredRep',
    Recruit: 'Recruit',

    all: () => {
        return [
            AGENT_METRIC_TYPE.Count,
            AGENT_METRIC_TYPE.PolicyStatusGroup,
            AGENT_METRIC_TYPE.Premium,
            AGENT_METRIC_TYPE.Prospect,
            AGENT_METRIC_TYPE.RegisteredRep,
            AGENT_METRIC_TYPE.Recruit,
        ];
    },
}

export const LOB = {
    Life: 'life',
    Annuity: 'annuity',
    HMA: 'hma',
    Ancillary: 'ancillary',

    all: () => {
        return [
            LOB.Life,
            LOB.Annuity,
            LOB.HMA,
            LOB.Ancillary,
        ];
    },
    defaultLines: () => { return [LOB.Life]; }
};

export const UNDERWRITING = {
    Guaranteed: 'guaranteed',
    FullyUnderwritten: 'fully-underwritten',
    Simplified: 'simplified',

    all: () => {
        return [ UNDERWRITING.Guaranteed, UNDERWRITING.FullyUnderwritten, UNDERWRITING.Simplified ];
    },
}

export const CHART_COLORS = {
    Classic: (mode) => {
        const classic = [ '#619ED6', '#6BA547', '#F7D027', '#E48F1B', '#B77EA3', '#E64345', '#60CEED', '#9CF168', '#F7EA4A', '#FBC543', '#FFC9ED', '#E6696E' ];
        switch (mode) {
            case UI_MODE.dark: return classic;
            default: return classic;
        }
    },
    KpiProgress: (mode) => {
        switch (mode) {
            case UI_MODE.dark: return ['#efefef', 'transparent'];
            default: return ['#666666', 'transparent'];
        }
    },
    KpiCurrent: (mode) => {
        switch (mode) {
            case UI_MODE.dark: return ['#B8D935', 'transparent'];
            default: return ['#B8D935', '#F4FBDB'];
        }
    },
    KpiPrior1: (mode) => {
        switch (mode) {
            case UI_MODE.dark: return ['#619ed6', 'transparent'];
            default: return ['#619ed6', '#dbe9f5'];
        }
    },
    KpiPrior2: (mode) => {
        switch (mode) {
            case UI_MODE.dark: return ['#F7D027', 'transparent'];
            default: return ['#F7D027', '#FDF5D3'];
        }
    },
};

export const COLORS = {
    Theme: [ '#619ED6', '#6BA547', '#F7D027', '#E48F1B', '#B77EA3', '#E64345', '#60CEED', '#9CF168', '#F7EA4A', '#FBC543', '#FFC9ED', '#E6696E' ],
    ThemeBg: [ 'rgba(97,158,214,0.2)', 'rgba(107,165,71,0.2)', 'rgba(247,208,39,0.2)', 'rgba(228,143,27,0.2)', 'rgba(183,126,163,0.2)', 'rgba(230,67,69,0.2)', 'rgba(96,206,237,0.2)', 'rgba(156,241,104,0.2)', 'rgba(247,234,74,0.2)', 'rgba(251,197,67,0.2)', 'rgba(255,201,237,0.2)', 'rgba(230,105,110,0.2)' ]
}

export const WIDGET = {
    ChartType: {
        Pie: 'pie',
        Bar: 'bar',
        Leaderboard: 'leaderboard',
        all: () => {
            return [
                WIDGET.ChartType.Pie,
                WIDGET.ChartType.Bar,
                WIDGET.ChartType.Leaderboard
            ];
        },
        default: () => { return WIDGET.ChartType.Pie; }
    },
    View: {
        Personal: 'personal',
        Team: 'team',
        Agency: 'agency',
        Downline: 'downline',
        Company: 'company',
        Mentor: 'mentor',
        all: (security, includeCompany, includeMentor = false) => {
            const isDashboardAdmin = security.isInRole([ROLE.DashboardAdmin]);
            const views = [];
            views.push(WIDGET.View.Personal);
            if (security.isTeam() || isDashboardAdmin) views.push(WIDGET.View.Team);
            if (security.isAgency() || isDashboardAdmin) views.push(WIDGET.View.Agency);
            views.push(WIDGET.View.Downline);
            if (includeCompany && security.isInRole(ROLE.DashboardAdmin)) views.push(WIDGET.View.Company);
            if (includeMentor && security.isInRole(ROLE.Mentor)) views.push(WIDGET.View.Mentor);
            return views;
        },
        default: () => { return WIDGET.View.Personal; }
    },
    GroupBy: {
        Agent: 'agent',
        Team: 'team',
        Agency: 'agency',
        Carrier: 'carrier',
        all: (security) => {
            const isDashboardAdmin = security.isInRole([ROLE.DashboardAdmin]);
            const groupBys = [];
            groupBys.push(WIDGET.GroupBy.Agent);
            if (security.isTeam() || isDashboardAdmin) groupBys.push(WIDGET.GroupBy.Team);
            if (security.isAgency() || isDashboardAdmin) groupBys.push(WIDGET.GroupBy.Agency);
            groupBys.push(WIDGET.GroupBy.Carrier);
            return groupBys;
        },
        forAgents: (security) => {
            const isDashboardAdmin = security.isInRole([ROLE.DashboardAdmin]);
            const groupBys = [];
            groupBys.push(WIDGET.GroupBy.Agent);
            if (security.isTeam() || isDashboardAdmin) groupBys.push(WIDGET.GroupBy.Team);
            if (security.isAgency() || isDashboardAdmin) groupBys.push(WIDGET.GroupBy.Agency);
            return groupBys;
        },
        default: () => { return WIDGET.GroupBy.Agent; }
    },
    Period: {
        Day: 'day',
        Week: 'week',
        Month: 'month',
        Year: 'year',
        All: 'all',
        all: () => {
            return [
                WIDGET.Period.Day,
                WIDGET.Period.Week,
                WIDGET.Period.Month,
                WIDGET.Period.Year
            ];
        },
        default: () => { return WIDGET.Period.Month; },
        toReportTimePeriod: (period) => {
            switch (period) {
                case WIDGET.Period.Day: return REPORT_TIME_PERIOD.DTD;
                case WIDGET.Period.Week: return REPORT_TIME_PERIOD.WTD;
                case WIDGET.Period.Month: return REPORT_TIME_PERIOD.MTD;
                case WIDGET.Period.Year: return REPORT_TIME_PERIOD.YTD;
                default: return period;
            }
        }
    },
    PolicyStatusGroup: {
        Submitted: 'submitted',
        Issued: 'issued',
        Paid: 'paid',
        Pending: 'pending',
        ClosedNeverInForce: 'closed-never-in-force',
        NoLongerInForce: 'no-longer-in-force',
        all: () => {
            return [
                WIDGET.PolicyStatusGroup.Submitted,
                WIDGET.PolicyStatusGroup.Issued,
                WIDGET.PolicyStatusGroup.Paid,
                WIDGET.PolicyStatusGroup.Pending,
                WIDGET.PolicyStatusGroup.ClosedNeverInForce,
                WIDGET.PolicyStatusGroup.NoLongerInForce
            ];
        },
        default: () => { return WIDGET.PolicyStatusGroup.Paid; }
    }
};

export const COMMISSION = {
    GroupBy: {
        Date: 'date',
        Policy: 'policy',
        Client: 'client',
        Carrier: 'carrier',
        all: () => {
            return [
                COMMISSION.GroupBy.Date,
                COMMISSION.GroupBy.Policy,
                COMMISSION.GroupBy.Carrier,
                COMMISSION.GroupBy.Client,
            ];
        },
        default: () => { return COMMISSION.GroupBy.Date; }
    },
};

export const CREDIT_TYPE = {
    SubmittedGross: 'submitted-gross',
    SubmittedActual: 'submitted-actual',
    SubmittedBonus: 'submitted-bonus',
    IssuedGross: 'issued-gross',
    IssuedActual: 'issued-actual',
    IssuedPromotion: 'issued-promotion',
    IssuedHierarchyBonus: 'issued-hierarchy-bonus',
    ContestBonus: 'contest-bonus',
    all: () => {
        return [
            CREDIT_TYPE.SubmittedGross,
            CREDIT_TYPE.SubmittedActual,
            CREDIT_TYPE.SubmittedBonus,
            CREDIT_TYPE.IssuedGross,
            CREDIT_TYPE.IssuedActual,
            CREDIT_TYPE.IssuedPromotion,
            CREDIT_TYPE.IssuedHierarchyBonus,
            CREDIT_TYPE.ContestBonus
        ];
    },
};

export const KPI = {
    requiresPolicyStatus: (metric) => { return metric === KPI.Metric.Applications || metric === KPI.Metric.Premium; },
    requiresLineOfBusiness: (metric) => { return metric === KPI.Metric.Applications || metric === KPI.Metric.Premium; },
    requiresPremiumType: (metric) => { return metric === KPI.Metric.Premium; },
    Metric: {
        Applications: 'applications',
        Premium: 'premium',
        Prospects: 'prospects',
        RegisteredReps: 'registered-reps',
        Recruits: 'recruits',
        WritingAgents: 'writing-agents',
        all: () => {
            return [
                KPI.Metric.Applications,
                KPI.Metric.Premium,
                KPI.Metric.Prospects,
                KPI.Metric.RegisteredReps,
                KPI.Metric.Recruits,
                KPI.Metric.WritingAgents
            ];
        },
        default: () => { return KPI.Metric.Premium; }
    },
    PremiumType: {
        SubmittedGross: 'submitted-gross',
        SubmittedActual: 'submitted-actual',
        SubmittedBonus: 'submitted-bonus',
        IssuedGross: 'issued-gross',
        IssuedActual: 'issued-actual',
        IssuedPromotion: 'issued-promotion',
        IssuedHierarchyBonus: 'issued-hierarchy-bonus',
        ContestBonus: 'contest-bonus',
        all: () => {
            return [
                KPI.PremiumType.SubmittedGross,
                KPI.PremiumType.SubmittedActual,
                KPI.PremiumType.SubmittedBonus,
                KPI.PremiumType.IssuedGross,
                KPI.PremiumType.IssuedActual,
                KPI.PremiumType.IssuedPromotion,
                KPI.PremiumType.IssuedHierarchyBonus,
                KPI.PremiumType.ContestBonus
            ];
        },
        default: () => { return KPI.PremiumType.IssuedActual; },
        toPolicyPremium: (premiumType) => {
            switch (premiumType) {
                case KPI.PremiumType.SubmittedGross: return 'submittedGross';
                case KPI.PremiumType.SubmittedActual: return 'submittedActual';
                case KPI.PremiumType.SubmittedBonus: return 'submittedBonus';
                case KPI.PremiumType.IssuedGross: return 'issuedGross';
                case KPI.PremiumType.IssuedActual: return 'issuedActual';
                case KPI.PremiumType.IssuedPromotion: return 'issuedPromotion';
                case KPI.PremiumType.IssuedHierarchyBonus: return 'issuedHierarchyBonus';
                case KPI.PremiumType.ContestBonus: return 'contestBonus';
                default: return '';
            }
        }
    }
};

export const ABOUT_TYPE = {
    Agent: 'Agent',
    Policy: 'Policy',
    Client: 'Client',
    Carrier: 'Carrier',
    Lead: 'Lead',
};

export const AGENT_PROPERTY = {
    LpfnId: { id: 'agentID', translationKey: 'profile-agent-id', type: 'String' },
    LifeLevel: { id: 'lifeLevel', translationKey: 'life-level', type: 'Level' },
    AnnuityLevel: { id: 'annuityLevel', translationKey: 'annuity-level', type: 'Level' },
    HmaLevel: { id: 'hmaLevel', translationKey: 'hma-level', type: 'Level' },
    CellPhone: { id: 'cellPhone', translationKey: 'profile-cell', type: 'Phone' },
    BusinessPhone: { id: 'businessPhone', translationKey: 'business-phone', type: 'Phone' },
    Email: { id: 'email', translationKey: 'email', type: 'String' },
    FirstContractDate: { id: 'firstContractDate', translationKey: 'agent-first-contract', type: 'Date' },
    FirstPolicyPaidDate: { id: 'firstPaidPolicyDate', translationKey: 'agent-first-policy-paid', type: 'Date' },
    FirstPolicySubmitDate: { id: 'firstSubmittedPolicyDate', translationKey: 'agent-first-policy-submitted', type: 'Date' },
    IsAgency: { id: 'isAgency', translationKey: 'profile-is-agency', type: 'Boolean' },
    IsTeam: { id: 'isTeam', translationKey: 'profile-is-team', type: 'Boolean' },
    RegistrationDate: { id: 'registeredDate', translationKey: 'registered-date', type: 'Date' },

    find: (id) => {
        return AGENT_PROPERTY.tagOptions().find(x => x.id === id);
    },

    tagOptions: () => {
        return [
            AGENT_PROPERTY.LpfnId,
            AGENT_PROPERTY.LifeLevel,
            AGENT_PROPERTY.AnnuityLevel,
            AGENT_PROPERTY.HmaLevel,
            AGENT_PROPERTY.CellPhone,
            AGENT_PROPERTY.BusinessPhone,
            AGENT_PROPERTY.Email,
            AGENT_PROPERTY.FirstContractDate,
            AGENT_PROPERTY.FirstPolicyPaidDate,
            AGENT_PROPERTY.FirstPolicySubmitDate,
            AGENT_PROPERTY.IsAgency,
            AGENT_PROPERTY.IsTeam,
            AGENT_PROPERTY.RegistrationDate,
        ];
    },
};
