import { PLATFORM } from 'aurelia-pal';
import { RouterEvent } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { I18n } from 'common/i18n';
import { Page, Notifier } from 'common/ui';
import { Sites } from 'services/sites';
import { DialogService } from 'aurelia-dialog';
import { Nylas } from 'services/nylas';
import { Menus } from 'services/menus';
import { Members } from 'services/members';
import { Drawer } from 'common/drawer';
import { AgentCardDialog } from './agents/dialogs/agent-card-dialog';
import { PolicyCardDialog } from './policies/dialogs/policy-card-dialog';
import { LeadCardDialog2 } from './leads/dialogs/lead-card-dialog2';
import { c } from 'common/common';
import { ROLE } from 'common/constants';
import { MenuController } from 'resources/base-classes/menu-controller';
import environment from '../../config/environment.json';
import { Dialer, DIALER_TEAM } from 'services/dialer';
PLATFORM.moduleName('./agents/dialogs/agent-card-dialog');
PLATFORM.moduleName('./policies/dialogs/policy-card-dialog');
PLATFORM.moduleName('./leads/dialogs/lead-card-dialog2');

export class Index extends MenuController {
    static inject = [EventAggregator, Security, I18n, Page, Notifier, Sites, DialogService, Nylas, Menus, Members, Dialer];
    _page;
    _notifier;
    _sites;
    _dialogService;
    _nylas;
    _menus;
    _members;
    _dialer;

    isAdmin = false;
    adminToolsOn = false;
    showQuickActionsButton = true;
    showSearch = true;
    showNotifications = true;

    newClientVersionAvailable = false;
    canAddToMobileMenu = false;

    _handlers = [];

    constructor(ea, security, i18n, page, notifier, sites, dialogService, nylas, menus, members, dialer) {
        super(ea, 'members')
        this.security = security;
	    this.i18n = i18n;
	    this.isAdmin = security.isAdmin;
	    this._page = page;
        this._notifier = notifier;
        this._sites = sites;
        this._dialogService = dialogService;
        this._nylas = nylas;
        this._menus = menus;
        this._members = members;
        this._dialer = dialer;
	}

    async activate() {
        try {
            await this.i18n.loadNamespace(['note', 'policy', 'client', 'lead']);
        } catch (err) {
            console.log(err);
        }
    }

	configureRouter(config, router) {
		config.map([
            {
                route: ['', 'home', 'home/:loadAction', 'dashboard', 'dashboard/:dashboardKey'],
                moduleId: PLATFORM.moduleName('members/dashboard'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'dashboard', description: 'dashboard-description', canAddToMobileMenu: false },
            },
            {
                route: 'onboarding',
                moduleId: PLATFORM.moduleName('members/onboarding/onboarding'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'onboarding', description: 'onboarding-description', canAddToMobileMenu: false },
            },
            {
                route: ['agent', 'agent/:id', 'agent/:id/:tab', 'my-profile'],
                moduleId: PLATFORM.moduleName('members/agents/agent-card'),
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'agent', description: 'agent-description', canAddToMobileMenu: false },
            },
            {
                route: ['policy', 'policy/:id'],
                moduleId: PLATFORM.moduleName('members/policies/policy-card'),
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'policy', description: 'policy-description', canAddToMobileMenu: false },
            },
            {
                route: ['rapid-start', 'rapid-start/:slug'],
                moduleId: PLATFORM.moduleName('members/rapid-start/rapid-start'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'nav-basics', description: 'rapid-start-description', canAddToMobileMenu: true },
            },
            {
                route: 'rapid-start/:rapidStartSlug/:slug',
                moduleId: PLATFORM.moduleName('members/rapid-start/topic'),
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'nav-basics', description: 'rapid-start-description', canAddToMobileMenu: true },
            },
            {
                route: ['my-team', 'my-team/:view'],
                moduleId: PLATFORM.moduleName('members/my-team'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'my-team', description: 'my-team-description', canAddToMobileMenu: true, icon: 'fa-duotone fa-people-group' },
            },
            {
                route: 'mentors',
                moduleId: PLATFORM.moduleName('members/mentors/_index'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: {},
            },
            {
                route: 'events',
                moduleId: PLATFORM.moduleName('members/events/events'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'events', description: 'events-description', canAddToMobileMenu: true, icon: 'fa-duotone fa-calendar-days' },
            },
            {
                route: 'events/corporate',
                moduleId: PLATFORM.moduleName('members/events/corporate-events'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'corporate-events', description: 'corporate-events-description', canAddToMobileMenu: true },
            },
            {
                route: 'events/corporate/:id/:type',
                moduleId: PLATFORM.moduleName('members/events/corporate-event-registrants'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'corporate-events', description: 'corporate-events-description', canAddToMobileMenu: true },
            },
            {
                route: ['resources', 'training'],
                moduleId: PLATFORM.moduleName('members/training/training'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'resources', description: 'resources-description', canAddToMobileMenu: true, icon: 'fa-duotone fa-bell-school' },
            },
            {
                route: ['training/:trainingSlug/:courseSlug', 'training/:trainingSlug/:courseSlug/:moduleSlug', 'resources/:trainingSlug/:courseSlug', 'resources/:trainingSlug/:courseSlug/:moduleSlug'],
                moduleId: PLATFORM.moduleName('members/training/course'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'resources', description: 'resources-description', canAddToMobileMenu: true, icon: 'fa-duotone fa-folder-open' },
            },
            {
                route: ['carriers','carriers/:slug'],
                moduleId: PLATFORM.moduleName('members/carriers/carrier'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { minimumLevel: 'L02', title: 'carriers', description: 'carriers-description', canAddToMobileMenu: true, icon: 'fa-duotone fa-building-shield', favoriteType: 'carrier' }
            },
            {
                route: 'sales-tools',
                moduleId: PLATFORM.moduleName('members/sales-tools/index'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: true },
            },
            {
                route: ['contests','rewards'],
                moduleId: PLATFORM.moduleName('members/contests/contests'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'contests', description: 'contests-description', canAddToMobileMenu: true },
            },
            {
                route: ['my-team/reports', 'my-team/reports/:reportType'],
                moduleId: PLATFORM.moduleName('members/my-team/reports/team-reports'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'my-team-reports', description: 'my-team-reports-description', canAddToMobileMenu: true },
            },
            {
                route: 'my-team/onboarding',
                moduleId: PLATFORM.moduleName('members/my-team/onboarding-status'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'my-team-onboarding', description: 'my-team-onboarding-description', canAddToMobileMenu: true, icon: 'fa-duotone fa-user-clock' },
            },
            {
                route: 'my-branding',
                moduleId: PLATFORM.moduleName('members/marketing/my-branding'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
            {
                route: 'marketing/advertising-submission',
                moduleId: PLATFORM.moduleName('members/marketing/advertising-submission'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
            {
                route: 'integrations',
                moduleId: PLATFORM.moduleName('members/integrations/_index'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
            {
                route: ['approval-requests','approval-requests/:id'],
                moduleId: PLATFORM.moduleName('members/approvals/approval-requests'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'approval-requests', description: 'approval-requests-description', canAddToMobileMenu: false },
            },
            {
                route: 'recruiting',
                moduleId: PLATFORM.moduleName('members/recruiting/_index'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'recruiting', description: 'recruiting-description', canAddToMobileMenu: true },
            },
            {
                route: 'sso',
                moduleId: PLATFORM.moduleName('members/sso/index'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
            {
                route: 'tasks',
                moduleId: PLATFORM.moduleName('members/tasks/index'),
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'tasks', description: 'tasks-description', canAddToMobileMenu: true },
            },
            {
                route: ['reminders', 'to-dos'],
                moduleId: PLATFORM.moduleName('members/to-dos/_index'),
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'to-dos', description: 'to-dos-description', canAddToMobileMenu: true },
            },
            {
                route: 'message-center',
                moduleId: PLATFORM.moduleName('members/message-center/index'),
                membersOnly: true,
                adminOnly: false,
                settings: { title: 'message-center', description: 'message-center-description', canAddToMobileMenu: true },
            },
            {
                route: 'leaderboard/:code/entries/:memberId',
                moduleId: PLATFORM.moduleName('members/leaderboards/entries'),
                nav: false,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'leaderboard-entries', description: 'leaderboard-entries-description', canAddToMobileMenu: false },
            },
            {
                route: 'lpfn',
                moduleId: PLATFORM.moduleName('members/lpfn/index'),
                nav: false,
                membersOnly: false,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
            {
                route: 'calculators',
                moduleId: PLATFORM.moduleName('members/calculators/index'),
                nav: false,
                membersOnly: false,
                adminOnly: false,
                settings: { canAddToMobileMenu: true },
            },
            {
                route: 'map',
                moduleId: PLATFORM.moduleName('members/map/index'),
                nav: false,
                membersOnly: false,
                adminOnly: false,
                settings: { title: 'map', description: 'map-description', canAddToMobileMenu: true },
            },
            {
                route: 'crm',
                moduleId: PLATFORM.moduleName('members/crm/index'),
                nav: false,
                membersOnly: false,
                adminOnly: false,
                settings: { title: 'crm', description: 'crm-description', canAddToMobileMenu: true },
            },
            {
                route: 'leads',
                moduleId: PLATFORM.moduleName('members/leads/_index'),
                nav: false,
                membersOnly: false,
                adminOnly: false,
                settings: { title: 'agent-leads', description: 'agent-leads-description', canAddToMobileMenu: true },
            },
            {
                route: 'analytics',
                moduleId: PLATFORM.moduleName('members/analytics/_index'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false }
            },
            {
				route: ['agent-tracking-system', 'ats'],
				moduleId: PLATFORM.moduleName('members/agent-tracking-system/_index'),
				nav: false,
				settings: { canAddToMobileMenu: false }
			},
            {
                route: ['_p/:id', 'pg/:id'],
                moduleId: PLATFORM.moduleName('members/pg'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: true },
            },
            {
                route: ['_w/:id', '_w/mobile/:id'],
                moduleId: PLATFORM.moduleName('members/widget'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
            {
                route: 'privacy-policy',
                moduleId: PLATFORM.moduleName('members/privacy-policy'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
            {
                route: 'terms-of-use',
                moduleId: PLATFORM.moduleName('members/terms-of-use'),
                nav: false,
                membersOnly: true,
                adminOnly: false,
                settings: { canAddToMobileMenu: false },
            },
		]);

		this.router = router;
	}

    attached() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.onboarding.completed, () => this._onboardingCompleted()));
        this.sidebarEl = document.getElementById('kt_app_sidebar');
        this._applyTheme();
        if (!this.security.agent.onboardingComplete) {
            this.showQuickActionsButton = false;
            this.showSearch = false;
            this.showNotifications = false;
            this.sidebarArea = 'onboarding';
            return;
        }
        this.sidebarArea = 'members';
        this.setMenu();
        this._initialize();
        this._setPageTitle();
    }

    async _initialize() {
        this._handlers.push(this._ea.subscribe(RouterEvent.Complete, (event) => {
            this._resetLogoutTimer();
        }));
        this._handlers.push(this._ea.subscribe(c.EventKeys.newClientVersion, () => this._newClientVersionDetected()));
        this._handlers.push(this._ea.subscribe(c.EventKeys.site.openProfile, (data) => this._openProfile(data)));
        this._handlers.push(this._ea.subscribe(c.EventKeys.site.openPolicy, (data) => this._openPolicy(data)));
        this._handlers.push(this._ea.subscribe(c.EventKeys.site.openLead, (data) => this._openLead(data)));
        this._handlers.push(this._ea.subscribe(c.EventKeys.navigation.changing, () => this.closeMenu()));
        this._handlers.push(this._ea.subscribe(c.EventKeys.navigation.changed, () => this._setPageTitle()));
        this._handlers.push(this._ea.subscribe(c.EventKeys.site.setPageTitle, (data) => this._setPageTitle(data)));

        this.canAutoDial = await this._dialer.canDial(DIALER_TEAM.NoPrompt);
        this._resetLogoutTimer();
        this._nylas.loadScript();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
        if (this._logoutTimer) window.clearTimeout(this._logoutTimer);
        this.closeMenu();
    }

    _setPageTitle(data) {
        try {
            const pageCode = this._mobileMenuCode();
            const pageSettings = this.router.currentInstruction.config.settings ?? {};
            this.pageTitle = data && data.title ? data.title : pageSettings.title;
            this.pageDescription = data && data.description ? data.description : pageSettings.description;
            this.pageIcon = data && data.icon ? data.icon : pageSettings.icon ?? 'fa-duotone fa-users';
            this.pageInMobileMenu = this._menus.isOnMobileMenu('Page', pageCode);
            this.canAddToMobileMenu = this.security.isInRole([ROLE.Admin, ROLE.DashboardAdmin]) && pageSettings.canAddToMobileMenu;
            this.favoriteType = pageSettings.favoriteType;
            if (this.router.currentInstruction.params) {
                this.favoriteId = this.router.currentInstruction.params.id ?? this.router.currentInstruction.params.code ?? this.router.currentInstruction.params.slug;
            }
        } catch (err) {
            console.log(err);
        }
    }

    addToMobileMenu() {
        try {
            const code = this._mobileMenuCode();
            this._menus.addToMobileMenu(null, 'Page', code, this.pageTitle, this.pageDescription, code, this.pageIcon);
            this.pageInMobileMenu = true;
        } catch (err) {
            console.log(err);
        }
    }

    async removeFromMobileMenu() {
        try {
            const code = this._mobileMenuCode();
            await this._menus.removeFromMobileMenu(null, 'Page', code);
            this.pageInMobileMenu = false;
        } catch (err) {
            console.log(err);
        }
    }

    _mobileMenuCode() {
        return `#${this.router.baseUrl}${this.router.currentInstruction.fragment}`;
    }

    closeMenu() {
        if (this._sidebarDrawer) this._sidebarDrawer.hide();
    }

    _onboardingCompleted() {
        this.showQuickActionsButton = true;
        this.showSearch = true;
        this.showNotifications = true;
        this.sidebarArea = 'members';
        this._initialize();
    }

    _newClientVersionDetected() {
        this.newClientVersionAvailable = true;
    }

    loadNewVersion() {
        window.location.reload(true);
    }

    toggleNewVersionHelp() {
        this.showNewVersionHelp = !this.showNewVersionHelp;
    }

    async _applyTheme() {
        try {
            const theme = await this._sites.theme();
            this.lightLogo = `${environment.static}/${theme.light.logoPath}`;
            this.darkLogo = `${environment.static}/${theme.dark.logoPath}`;
            this.lightIcon = `${environment.static}/files/themes/${theme.key}/favicons/favicon-96x96.png`;
            this.darkIcon = `${environment.static}/files/themes/${theme.key}/favicons/favicon-96x96.png`;
        } catch (err) {
            console.log(err);
        }
    }

    _resetLogoutTimer() {
        if (this._logoutTimer) window.clearTimeout(this._logoutTimer);
        this._logoutTimer = window.setTimeout(() => {
            this.security.logout();
            this._notifier.info('inactivity-timeout-message', 'security-notice', true);
            this.router.navigate('#/');
        }, 4 * 60 * 60 * 1000); // 4 hrs = 4 * 60 * 60 * 1000
    }

    _openProfile(data) {
        const model = { id: data.member ? data.member.id : data.memberId };
        model.tab = data.tab;
	    this._dialogService.open({ viewModel: AgentCardDialog, model, ignoreTransitions: true });
    }

    _openPolicy(data) {
        const model = { id: data.policyId };
        model.tab = data.tab;
	    this._dialogService.open({ viewModel: PolicyCardDialog, model, ignoreTransitions: true });
    }

    _openLead(data) {
        const model = { id: data.leadId };
        model.tab = data.tab;
        model.action = data.action;
        this._dialogService.open({ viewModel: LeadCardDialog2, model, ignoreTransitions: true });
    }

    openSidebar() {
        try {
            if (!this._sidebarDrawer) this._sidebarDrawer = new Drawer(this.sidebarEl);
            this._sidebarDrawer.show();
        } catch (err) {
            console.log(err);
        }
    }

    showNavigation(row) {
        if (!row.settings) return true;
        if (row.settings.adminOnly && !this.isAdmin) return false;
        if (row.settings.minimumLevel) {
            return this.security.isInMinimumLevel(row.settings.minimumLevel);
        }
        return true;
    }

	recordPageView(key, closeNavigation) {
	    this._page.track(key);
	    if (closeNavigation) this.closeNav();

	    return true;
	}

	closeNav() {
	    var navMain = $('#members-navbar');
        navMain.collapse('hide');
	    return true;
	}
}
