import { Api } from 'common/server';

export class Wills {
    static inject = [Api];
    _api;

    constructor(api) {
        this._api = api;
    }

    async fastWillSso() {
        return await this._api.get('sso/fastwill');
    }
}
