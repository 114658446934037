import { Security } from 'common/security';
import { Wills } from 'services/wills';
import { Notifier } from 'common/ui';

export class TabSites {
    static inject = [Security, Wills, Notifier];
    security;
    _wills;
    _notifier;

    constructor(security, wills, notifier) {
        this.security = security;
        this._wills = wills;
        this._notifier = notifier;
    }

    activate(model) {
        this.agentId = model.agentId;
        this.viewingMyProfile = this.security.isAuthenticatedMember(this.agentId);
		this.canHaveAgentSite = this.security.canHaveAgentSite();
    }

    async ssoFastWill() {
        try {
            if (this.ssoing) return;
            this.ssoing = true;
            const sso = await this._wills.fastWillSso();
            if (sso.error) {
                this._notifier.error(sso.error);
                return;
            }
            this.ssoUrl = sso.redirectUrl;
            window.setTimeout(() => this.ssoLinkEl.click(), 250);
        } catch (err) {
            console.log(err);
        } finally {
            this.ssoing = false;
        }
    }
}
