import { bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Notifier } from 'common/ui';
import { Google } from 'services/google';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { I18n } from 'common/i18n';
import { HttpClient } from 'aurelia-http-client';
import { Geography } from '../services/geography';
import * as topojson from 'topojson-client';
import turfCentroid from '@turf/centroid';

export class SlippyMap {
    static inject = [Element, EventAggregator, Notifier, Google, I18n, Geography];
    _ea;
    _notifier;
    _google;
    _i18n;
    _geography;

    @bindable markers;
    @bindable selectMarkers = false;
    @bindable popupCallback;
    @bindable hoverInfoCallback;
    @bindable({ defaultBindingMode: bindingMode.fromView }) visibleMarkers;
    _mapMarkers = [];
    @bindable({ defaultBindingMode: bindingMode.fromView }) currentLocation;
    @bindable highlightMarker;
    @bindable selectMarker
    @bindable allowFullScreen = false;
    @bindable streetView;
    @bindable topojson;
    @bindable resetTopojsonStyles;
    @bindable zoom = 6;
    @bindable heatmap;
    @bindable geojson;
    @bindable removeGeojsonIds;
    @bindable selectWithDrawingTools = false;
    @bindable clearSelected;
    @bindable selectWithCounties = false;
    @bindable selectedEventKey;
    @bindable sendSelectedEventKey;

    _domParser;

    _infoWindow;
    _topojsonLayers = [];
    _geojsonLabels = [];
    _handlers = [];

    constructor(element, ea, notifier, google, i18n, geography) {
        this._element = element;
        this._ea = ea;
        this._notifier = notifier;
        this._google = google;
        this._i18n = i18n;
        this._geography = geography;
    }

    attached() {
        if (this.selectedEventKey) this._handlers.push(this._ea.subscribe(this.selectedEventKey, (data) => this._onSelectedIdsChanged(data.ids)));
        this._domParser = new DOMParser();
        this._initializeMap();
        this._isAttached = true;
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    @computedFrom('_isAttached', '_map')
    get isMapLoaded() {
        return this._map && this._isAttached;
    }

    streetViewChanged() {
        try {
            if (!this._map || !this._panorama) return;
            if (!this.streetView) {
                if (this._panorama.getVisible() === true) this._panorama.setVisible(false);
                return;
            }
            const streetViewPosition = { lat: this.streetView.latitude, lng: this.streetView.longitude };
            this._panorama.setPosition(streetViewPosition);
            this._panorama.setPov({ heading: 0, pitch: 0 });
            this._panorama.setVisible(true);
        } catch (err) {
            console.log(err);
        }
    }

    selectMarkerChanged() {
        try {
            if (this._onMarkerClicked) {
                this._onMarkerClicked = false;
                return;
            }
            if (!this._mapMarkers) return;
            if (this._mapMarkerPopup) {
                this._mapMarkerPopup.close();
            }
            if (!this.selectMarker) return;
            const selectedMarker = this._mapMarkers.find(x => x.locationId === this.selectMarker);
            if (!selectedMarker) return;
            this._onMarkerClick(selectedMarker, true, true);
            this._map.setOptions({
                center: selectedMarker.marker.position,
                zoom: Number(15)
            });
        } catch (err) {
            console.log(err);
        }
    }

    _onSelectedIdsChanged(ids) {
        try {
            this._clearSelected(false);
            ids.forEach(id => {
                const mapMarker = this._mapMarkers.find(x => x.locationId === id);
                if (!mapMarker) return;
                this._onMarkerClick(mapMarker, false, false, true);
            });
        } catch (err) {
            console.log(err);
        }
    }

    async markersChanged() {
        try {
            if (!this._map) {
                this._loadMarkers = true;
                return;
            }
            if (this._mapMarkers.length) {
                // Remove the existing markers
                this._mapMarkers.forEach(m => {
                    m.marker.setMap(null);
                });
                this._mapMarkers.length = 0;
                this._mapMarkers = [];
            }

            if (!this.markers) return;

            if (!this._mapMarkerPopup) {
                this._mapMarkerPopup = new google.maps.InfoWindow({ content: '', disableAutoPan: true });
                this._mapMarkerPopup.addListener('closeclick', () => this._onMarkerClosed());
            }
            if (!this._mapMarkerHover) {
                this._mapMarkerHover = new google.maps.InfoWindow({ content: '', disableAutoPan: true });
            }

            const markerBounds = new google.maps.LatLngBounds();
            this._mapMarkers = this.markers.map((m, i) => {
                if (!m.lat || !m.lng) return;
                const faPin = this._faPin(m.marker);
                let highlightFaPin;
                if (this.selectMarkers) {
                    highlightFaPin = this._faPin(m.marker, '#00ff00', '#ff0000', '#00ff00');
                }
                const marker = new google.maps.marker.AdvancedMarkerElement({
                    position: new google.maps.LatLng(m.lat, m.lng),
                    content: m.selected && highlightFaPin ? highlightFaPin.element : faPin.element,
                });
                markerBounds.extend(marker.position);
                const mapMarker = { locationId: m.id, marker, faPin, highlightFaPin, selected: m.selected };
                marker.addListener('click', () => this._onMarkerClick(mapMarker));
                if (this.hoverInfoCallback) {
                    marker.content.addEventListener('mouseenter', () => this._onMarkerMouseover(mapMarker));
                    marker.content.addEventListener('mouseleave', () => this._onMarkerMouseout());
                }
                return mapMarker;
            });

            if (this.markers.length) {
                window.setTimeout(() => {
                    this._map.fitBounds(markerBounds);
                }, 50);
            }

            this._setMarkerCluster();
        } catch (err) {
            console.log(err);
        }
    }

    _faPin(iconClass, glyphColor = '#ffffff', background = '#ff0000', borderColor = '#cccccc') {
        const icon = document.createElement('div');
        icon.innerHTML = `<i class="${iconClass}" style="color:${glyphColor}"></i>`;
        return new google.maps.marker.PinElement({
            glyph: icon,
            glyphColor,
            background,
            borderColor,
        });
    }

    async _onMarkerMouseover(mapMarker) {
        try {
            let content = mapMarker.locationId;
            if (this.hoverInfoCallback) {
                content = await this.hoverInfoCallback(mapMarker.locationId);
            }
            if (content.header) {
                this._mapMarkerHover.setHeaderContent(content.header);
                this._mapMarkerHover.setContent(content.body);
            } else {
                this._mapMarkerHover.setContent(content);
            }
            this._mapMarkerHover.open(this._map, mapMarker.marker);
        } catch (err) {
            console.log(err);
        }
    }

    _onMarkerMouseout() {
        if (this._mapMarkerHover) this._mapMarkerHover.close();
    }

    async _onMarkerClick(marker, publishEvent = true, ignoreSelectMarkers = false, fromDrawing = false) {
        try {
            this._onMarkerClicked = true;
            window.setTimeout(() => this._onMarkerClicked = false, 1000);
            this._element.dispatchEvent(new CustomEvent('marker-clicked', { bubbles: true, detail: { id: marker.locationId } }));

            if (this.selectMarkers && !ignoreSelectMarkers) {
                marker.selected = !marker.selected;
                if (marker.selected) {
                    marker.marker.content = marker.highlightFaPin.element;
                } else {
                    marker.marker.content = marker.faPin.element;
                }
                if (publishEvent) {
                    this._publishMarkersSelected();
                }

                if (fromDrawing) return;

                if (!this.popupCallback) {
                    return;
                }
            }

            let content = marker.locationId;
            if (this.popupCallback) {
                content = await this.popupCallback(marker.locationId);
            }
            if (content.header) {
                this._mapMarkerPopup.setHeaderContent(content.header);
                this._mapMarkerPopup.setContent(content.body);
            } else {
                this._mapMarkerPopup.setContent(content);
            }
            this._mapMarkerPopup.open(this._map, marker.marker);
            this._element.dispatchEvent(new CustomEvent('marker-opened', { bubbles: true, detail: { id: marker.locationId } }));
        } catch (err) {
            console.log(err);
        }
    }

    _publishMarkersSelected() {
        const selectedIds = this._mapMarkers.filter(x => x.selected)?.map(x => x.locationId) ?? [];
        this._element.dispatchEvent(new CustomEvent('markers-selected', { bubbles: true, detail: { ids: selectedIds } }));
        if (this.sendSelectedEventKey) this._ea.publish(this.sendSelectedEventKey, { ids: selectedIds });
    }

    _onMarkerClosed() {
        this._element.dispatchEvent(new CustomEvent('marker-closed', { bubbles: true, detail: {} }));
    }

    clearSelectedChanged() {
        if (!this.clearSelected) return;
        this._clearSelected();
    }

    _clearSelected(publishEvent = true) {
        try {
            if (!this._mapMarkers.length) return;
            for (let i = 0; i < this._mapMarkers.length; i++) {
                this._mapMarkers[i].selected = true;
                let publish = i === this._mapMarkers.length - 1;
                if (!publishEvent) publish = false;
                this._onMarkerClick(this._mapMarkers[i], publish, false, true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * Google map is super slow with rendering many markers
     * Only display the markers that are in the visible bounds
     */
    _setMarkerCluster() {
        if (!this._map || !this._mapMarkers) return;
        try {
            if (this._mapMarkerCluster) {
                this._mapMarkerCluster.setMap(null);
                this._mapMarkerCluster = null;
            }
            const mapBounds = this._map.getBounds();
            if (!mapBounds) return;
            this._mapMarkers.forEach(mm => {
                if (mm.marker.map === this._map) mm.marker.setMap(null);
                mm.marker.display = mapBounds.contains(mm.marker.position);
            });
            const displayMarkers = this._mapMarkers.filter(x => x.marker.display) || [];
            this.visibleMarkers = displayMarkers.map((m, i) => {
                return {
                    id: m.locationId,
                    position: {
                        latitude: m.marker.position.lat,
                        longitude: m.marker.position.lng
                    }
                };
            });

            this._mapMarkerCluster = new MarkerClusterer({ markers: displayMarkers.map(x => x.marker), map: this._map });
        } catch (err) {
            console.log(err);
        }
    }

    /**
     * Google map is super slow with rendering many markers
     * This will clear the markers if there are too many when the map starts to change
     */
    _clearMarkerCluster() {
        try {
            if (!this._mapMarkerCluster) return;
            const displayedMarkers = (this._mapMarkers.filter(x => x.marker.display) || []).length;
            if (displayedMarkers < 100) return;
            this._mapMarkerCluster.setMap(null);
        } catch (err) {
            console.log(err);
        }
    }

    geojsonChanged() {
        if (!this.geojson || !this._map || !this._isAttached) return;
        this._addGeojson();
    }

    resetGeojsonStylesChanged() {
        if (!this._map) return;
        this._map.data.revertStyle();
    }

    removeGeojsonIdsChanged() {
        if (!this.removeGeojsonIds || !this.isMapLoaded) return;
        try {
            this.removeGeojsonIds.forEach(id => {
                const f = this._map.data.getFeatureById(id);
                if (f) {
                    this._map.data.remove(f);
                }
                const label = this._geojsonLabels.find(x => x.id === id);
                if (label) {
                    label.marker.setMap(null);
                    label.el.remove();
                }
            });
        } catch (err) {
            console.log(err);
        }
    }

    _zoomToFeature(feature) {
        try {
            if (!this.isMapLoaded || !feature) return;

            let bounds = new google.maps.LatLngBounds();
            feature.getGeometry().forEachLatLng((latlng) => {
                bounds.extend(latlng);
            });
            this._map.fitBounds(bounds, 10)
        } catch (err) {
            console.log(err);
        }
    }

    async _addGeojson() {
        try {
            this._map.data.setStyle(this.geojson.style);
            const listenersLoaded = google.maps.event.hasListeners(this._map.data, 'click');
            if (this.geojson.click && !listenersLoaded) {
                this._map.data.addListener('click', (event) => {
                    if (this.geojson.click.callback) this.geojson.click.callback(event.feature);
                    if (this.geojson.click.setStyle) {
                        const style = this.geojson.click.setStyle(event.feature);
                        if (style) {
                            this._map.data.overrideStyle(event.feature, style);
                        } else {
                            this._map.data.revertStyle(event.feature);
                        }
                    }
                    if (this.geojson.click.zoomTo) {
                        this._zoomToFeature(event.feature);
                    }
                });
            }
            if (this.geojson.label.minZoom && !listenersLoaded) {
                this._map.addListener('zoom_changed', () => {
                    const zoom = this._map.getZoom();
                    if (this.geojson.label.minZoom) {
                        if (zoom < this.geojson.label.minZoom) {
                            this._geojsonLabels.forEach(l => l.marker.setMap(null));
                        } else {
                            this._geojsonLabels.forEach(l => l.marker.setMap(this._map));
                        }
                    }
                });
            }
            if (this.geojson.label) {
                 this.geojson.labels = [];
                // add a point with a label for each geojson feature
                this.geojson.data.features.forEach((f) => {
                    const centroid = turfCentroid(f.geometry);
                    const lat = centroid.geometry.coordinates[1];
                    const lng = centroid.geometry.coordinates[0];
                    const label = document.createElement('div');
                    label.classList.add(this.geojson.label.cssClass)
                    label.textContent = this.geojson.label.template(f);
                    const featureLabelMarker = new google.maps.marker.AdvancedMarkerElement({
                        map: this._map,
                        position: { lat, lng },
                        content: label,
                    });
                    this._geojsonLabels.push({ id: f.id, el: label, marker: featureLabelMarker });
                });
            }
            if (this.geojson.mouseover && !listenersLoaded) {
                this._map.data.addListener('mouseover', (event) => {
                    if (this.geojson.mouseover.setStyle) {
                        const style = this.geojson.mouseover.setStyle(event.feature);
                        if (style) this._map.data.overrideStyle(event.feature, style);
                    }
                });
                this._map.data.addListener('mouseout', (event) => {
                    if (this.geojson.mouseover.setStyle) {
                        const style = this.geojson.mouseover.setStyle(event.feature);
                        if (style) this._map.data.revertStyle(event.feature);
                    }
                });
            }
            this._map.data.addGeoJson(this.geojson.data);
            if (this.geojson.label.minZoom) {
                google.maps.event.trigger(this._map, 'zoom_changed');
            }
        } catch (err) {
            console.log(err);
        }
    }

    topojsonChanged() {
        if (!this.topojson || !this.isMapLoaded) return;
        this._addTopoJson();
    }

    resetTopojsonStylesChanged() {
        if (!this._map) return;
        this._map.data.revertStyle();
    }

    _clearTopoJson(id) {
        if (!id || !this.isMapLoaded || !this._topojsonLayers) return;
        try {
            const idx = this._topojsonLayers.findIndex(x => x.id === id);
            if (idx < 0) return;
            this._topojsonLayers[idx].features.forEach(f => {
                this._map.data.remove(f);
            });
            this._topojsonLayers[idx].labels.forEach(l => {
                l.setMap(null);
                console.log(l);
            });
            this._topojsonLayers[idx].labelEls.forEach(el => {
                el.remove();
            });
            google.maps.event.clearInstanceListeners(this._map.data);
            this._topojsonLayers.splice(idx, 1);
        } catch (err) {
            console.log(err);
        }
    }

    async _addTopoJson() {
        try {
            if (this.topojson.clearOnReload) this._clearTopoJson(this.topojson.id);
            const idx = this._topojsonLayers.findIndex(x => x.id === this.topojson.id);
            if (idx < 0) this._topojsonLayers.push({ id: this.topojson.id, features: [], labels: [], labelEls: [] });
            const topojsonLayer = this._topojsonLayers.find(x => x.id === this.topojson.id);

            const httpClient = new HttpClient();
            const response = await httpClient.get(this.topojson.url);
            const data = JSON.parse(response.response);
            let geojson = topojson.feature(data, data.objects[this.topojson.objectName]);
            if (this.topojson.includeIds && this.topojson.includeIds.length) {
                geojson.features = geojson.features.filter(x => this.topojson.includeIds.includes(x.id));
            }
            this._map.data.setStyle(this.topojson.style);
            if (this.topojson.click) {
                this._map.data.addListener('click', (event) => {
                    if (this.topojson.click.callback) this.topojson.click.callback(event.feature);
                    if (this.topojson.click.setStyle) {
                        const style = this.topojson.click.setStyle(event.feature);
                        if (style) this._map.data.overrideStyle(event.feature, style);
                        else this._map.data.revertStyle(event.feature);
                    }
                });
            }
            // if (this.topojson.label.minZoom) {
            //     this._map.addListener('zoom_changed', () => {
            //         const zoom = this._map.getZoom();
            //         if (this.topojson.label.minZoom) {
            //             if (zoom < this.topojson.label.minZoom) {
            //                 topojsonLayer.labels.forEach(l => l.setMap(null));
            //             } else {
            //                 topojsonLayer.labels.forEach(l => l.setMap(this._map));
            //             }
            //         }
            //     });
            // }
            // if (this.topojson.label) {
            //     // add a point with a label for each geojson feature
            //     geojson.features.forEach((f) => {
            //         const centroid = turfCentroid(f.geometry);
            //         const label = document.createElement('div');
            //         label.classList.add(this.topojson.label.cssClass)
            //         label.textContent = this.topojson.label.template(f);
            //         const featureLabelMarker = new google.maps.marker.AdvancedMarkerElement({
            //             map: this._map,
            //             position: { lat: centroid.geometry.coordinates[1], lng: centroid.geometry.coordinates[0] },
            //             content: label,
            //         });
            //         topojsonLayer.labels.push(featureLabelMarker);
            //         topojsonLayer.labelEls.push(label);
            //     });
            // }
            // if (this.topojson.mouseover) {
            //     this._map.data.addListener('mouseover', (event) => {
            //         if (this.topojson.mouseover.setStyle) {
            //             const style = this.topojson.mouseover.setStyle(event.feature);
            //             if (style) this._map.data.overrideStyle(event.feature, this.topojson.mouseover.style);
            //         }
            //     });
            //     const mouseoutListener = this._map.data.addListener('mouseout', (event) => {
            //         if (this.topojson.mouseover.setStyle) {
            //             const style = this.topojson.mouseover.setStyle(event.feature);
            //             if (style) this._map.data.revertStyle(event.feature);
            //         }
            //     });
            // }
            topojsonLayer.features = this._map.data.addGeoJson(geojson);
        } catch (err) {
            console.log(err);
        }
    }

    heatmapChanged() {
        if (!this.heatmap || !this.isMapLoaded) return;
        this._addHeatmap();
    }

    async _addHeatmap() {
        try {
            const data = [];
            this.heatmap.forEach(x => data.push(new google.maps.LatLng(x[1], x[0])));
            if (this._heatmapLayer) {
                this._heatmapLayer.setData(data);
            } else {
                this._heatmapLayer = new google.maps.visualization.HeatmapLayer({
                    data,
                    opacity: 0.75,
                });
                this._map.addListener('zoom_changed', () => {
                    const radius = this._heatmapZoomRadius();
                    this._heatmapLayer.setOptions({ radius });
                });
                this._heatmapLayer.setMap(this._map);
            }
        } catch (err) {
            console.log(err);
        }
    }

    _heatmapZoomRadius() {
        if (!this._map) return 20;
        const zoom = this._map.getZoom();
        if (zoom <= 3) return 5;
        if (zoom <= 4) return 10;
        if (zoom <= 5) return 13;
        if (zoom <= 6) return 16;
        if (zoom <= 7) return 19;
        if (zoom <= 8) return 25;
        if (zoom <= 9) return 45;
        if (zoom <= 10) return 75;
        if (zoom <= 11) return 125;
        return 175;
    }

    async gotoCurrentLocation() {
        try {
            const position = await this._currentLocation();
            this._map.setCenter(position);
        } catch (err) {
            console.log(err);
            this._notifier.error('Geolocation service in the browser failed');
        }
    }

    _currentLocation() {
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                resolve(null);
                return;
            }
            try {
                navigator.geolocation.getCurrentPosition((position) => {
                        const pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        this.currentLocation = { latitude: pos.lat, longitude: pos.lng };
                        resolve(pos);
                        return;
                    },
                    () => {
                        reject();
                    },
                );
            } catch (err) {
                console.log(err);
                reject();
            }
        });
    }

    async _centerMapOnCurrentLocation(zoom = 14) {
        try {
            const position = await this._currentLocation();
            if (!position) return;
            this._map.setOptions({ center: position, zoom });

            if (this._currentLocationMarker) {
                this._currentLocationMarker.setMap(this._map);
                this._currentLocationMarker.position = position;
            }
        } catch (err) {
            console.log(err);
        }
    }

    _addCurrentLocationControls() {
        try {
            const controlDiv = document.createElement('div');
            controlDiv.className = 'lpfn-map-button-container';

            const locationButton = document.createElement('button');
            locationButton.className = 'lpfn-map-button';
            locationButton.title = this._i18n.tr('map-your-location');
            controlDiv.appendChild(locationButton);
        
            const secondChild = document.createElement('i');
            secondChild.className = 'fas fa-crosshairs';
            locationButton.appendChild(secondChild);
        
            google.maps.event.addListener(this._map, 'center_changed', function () {
                secondChild.style['background-position'] = '0 0';
            });
        
            locationButton.addEventListener('click', () => this._centerMapOnCurrentLocation());
        
            controlDiv.index = 1;
            this._map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);

            // Add pulsing marker
            const locationSvgString = `<svg width="40" height="40" viewbox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" fill="none" r="10" stroke="#0080ff" stroke-width="2">
                    <animate attributeName="r" from="8" to="20" dur="1.5s" begin="0s" repeatCount="indefinite"/>
                    <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
                </circle>
                <circle cx="20" cy="20" fill="#0080ff" r="7"/>
            </svg>`;
            const locationSvg = this._domParser.parseFromString(locationSvgString, 'image/svg+xml').documentElement;

            this._currentLocationMarker = new google.maps.marker.AdvancedMarkerElement({
                position: new google.maps.LatLng(0, 0),
                draggable: false,
                content: locationSvg,
            });
            this._currentLocationMarker.content.style.transform = 'translate(10%, 10%)';
        } catch (err) {
            console.log(err);
        }
    }

    async _initializeMap() {
        try {
            await this._google.load();
            const center = { lat: 40.26114, lng: -99.30101 };
            const zoom = Number(this.zoom);
            const options = { mapId: 'LPFN_MAP', center, zoom, gestureHandling: 'greedy', fullscreenControl: this.allowFullScreen };
            this._map = new google.maps.Map(this.slippyMapEl, options);
            this._addCurrentLocationControls();
            this._centerMapOnCurrentLocation(zoom);
            this._map.addListener('idle', () => this._setMarkerCluster());
            this._map.addListener('center_changed', (e) => this._clearMarkerCluster(e));
            //this._map.addListener('zoom_changed', () => { console.log(this._map.getZoom()); });
            if (this._loadMarkers) this.markersChanged();
            if (this.topojson) this._addTopoJson();
            if (this.geojson) this._addGeojson();
            if (this.heatmap && this.heatmap.length) this._addHeatmap();
            this._panorama = this._map.getStreetView();
            this._panorama.addListener('position_changed', () => this._setStreetViewHeading());
            this._panorama.addListener('status_changed', () => this._streetViewStatusChanged());
            this._panorama.addListener('visible_changed', () => {
                if (this._panorama && !this._panorama.getVisible()) {
                    this._element.dispatchEvent(new CustomEvent('streetview-closed', { bubbles: true, detail: { streetView: this.streetView } }));
                }
            });

            this._infoWindow = new google.maps.InfoWindow({});
            if (this.selectWithDrawingTools) {
                this._addDrawingTools();
            }
            if (this.selectWithCounties) {
                this._addCounties();
            }
        } catch (err) {
            console.log(err);
        }
    }

    _addDrawingTools() {
        this._drawingMgr = new google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: [
                    google.maps.drawing.OverlayType.CIRCLE,
                    google.maps.drawing.OverlayType.POLYGON,
                    google.maps.drawing.OverlayType.RECTANGLE,
                ]
            },
            circleOptions: {
                fillColor: '#ffffff',
                fillOpacity: 0.2,
                strokeWeight: 1,
                clickable: false,
                draggable: true,
                editable: true,
                zIndex: 1,
            },
            polygonOptions: {
                fillColor: '#ffffff',
                fillOpacity: 0.2,
                strokeWeight: 1,
                clickable: false,
                draggable: true,
                editable: true,
                zIndex: 1,
            },
            rectangleOptions: {
                fillColor: '#ffffff',
                fillOpacity: 0.2,
                strokeWeight: 1,
                clickable: false,
                draggable: true,
                editable: true,
                zIndex: 1,
            }
        });
        this._drawingMgr.setMap(this._map);
        google.maps.event.addListener(this._drawingMgr, 'overlaycomplete', (event) => {
            if (this._drawing) {
                this._drawing.setMap(null);
                this._drawing = null;
            }
            this._drawing = event.overlay;
            this._drawing.type = event.type;
            this._drawingChanged();

            switch (event.type) {
                case 'polygon':
                    google.maps.event.addListener(this._drawing, 'mouseup', this._drawingChanged);
                    break;
                case 'rectangle':
                case 'circle':
                    this._drawing.addListener('bounds_changed', this._drawingChanged);
                    break;
            }
            this._drawingMgr.setOptions({
                drawingMode: null
            });
        });
        const clearBtn = document.createElement('button');
        clearBtn.classList.add('lpfn-gmap-btn');
        clearBtn.classList.add('lpfn-gmap-top');
        clearBtn.innerHTML = '<i class="fa-duotone fa-solid fa-trash"></i>';
        clearBtn.addEventListener('click', () => {
            if (!this._drawing) return;
            this._drawing.setMap(null);
            this._drawing = null;
        });
        this._map.controls[google.maps.ControlPosition.TOP_CENTER].push(clearBtn);
    }

    _drawingChanged = (ev) => {
        try {
            if (this._mapMarkers) {
                let polygon = new google.maps.Polygon();
                switch (this._drawing.type) {
                    case 'circle':
                        const numSides = 60;
                        const circlePoints = [];
                        const angleStep = 360 / numSides;
                    
                        for (let i = 0; i < numSides; i++) {
                            const angle = i * angleStep;
                            const radian = angle * (Math.PI / 180);
                            const lat = this._drawing.center.lat() + (this._drawing.radius * Math.cos(radian)) / 111320; // 111320 meters per degree latitude
                            const lng = this._drawing.center.lng() + (this._drawing.radius * Math.sin(radian)) / (111320 * Math.cos(this._drawing.center.lat() * (Math.PI / 180)));
                            circlePoints.push(new google.maps.LatLng(lat, lng));
                        }
                        polygon.setPath(circlePoints);
                        break;
                    case 'polygon':
                        polygon.setPaths(this._drawing.getPaths());
                        break;
                    case 'rectangle':
                        const bounds = this._drawing.getBounds();
                        const ne = bounds.getNorthEast();
                        const sw = bounds.getSouthWest();
                        const nw = new google.maps.LatLng(ne.lat(), sw.lng());
                        const se = new google.maps.LatLng(sw.lat(), ne.lng());
                        
                        const polygonCoords = [nw, ne, se, sw];
                        polygon.setPaths(polygonCoords);
                        break;
                }

                this._selectMapMarkersWithinPolygon(polygon);
            }
        } catch (err) {
            console.log(err);
        }
    };

    _selectMapMarkersWithinPolygon(polygon) {
        for (let i = 0; i < this._mapMarkers.length; i++) {
            const isInside = google.maps.geometry.poly.containsLocation(this._mapMarkers[i].marker.position, polygon);
            if (!isInside) continue;
            this._mapMarkers[i].selected = false; // set to false, _onMarkerClick will set to true to select it
            this._onMarkerClick(this._mapMarkers[i], false, false, true);
        }
        this._publishMarkersSelected();
    }

    async _addCounties() {
        try {
            this._countiesVisibleStyle = {
                clickable: true,
                cursor: 'pointer',
                fillColor: '#ffffff',
                fillOpacity: 0.2,
                strokeColor: '#000000',
                strokeOpacity: 0.6,
                strokeWeight: 1,
                visible: true,
            };
            this._countiesHiddenStyle = {
                visible: false,
            };
            this._countiesData = new google.maps.Data({
                style: this._countiesHiddenStyle,
            });
            this._countiesData.addGeoJson(await this._geography.geojson.usCounties());
            this._countiesData.addListener('click', this._onCountyClick);
            this._countiesData.setMap(this._map);
            this._map.addListener('zoom_changed', this._countiesZoomChanged);

            this._countiesLabels = [];
            this._countiesData.forEach(f => {
                f.toGeoJson(gj => {
                    try {
                        const centroid = turfCentroid(gj);
                        const lat = centroid.geometry.coordinates[1];
                        const lng = centroid.geometry.coordinates[0];
                        const label = document.createElement('div');
                        label.classList.add('lpfn-map-label');
                        label.textContent = gj.properties.name;
                        const featureLabelMarker = new google.maps.marker.AdvancedMarkerElement({
                            map: null,
                            position: { lat, lng },
                            content: label,
                        });
                        this._countiesLabels.push({ id: f.id, el: label, marker: featureLabelMarker });
                    } catch (iErr) {
                        console.log(iErr);
                    }
                });
            });

            this._countiesZoomChanged();
        } catch (err) {
            console.log(err);
        }
    }

    _countiesZoomChanged = () => {
        try {
            const zoom = this._map.getZoom();
            const currentStyle = this._countiesData.getStyle();
            if (zoom >= 7) {
                if (!currentStyle.visible) {
                    this._countiesData.setStyle(this._countiesVisibleStyle);
                }
            } else {
                if (currentStyle.visible) {
                    this._countiesData.setStyle(this._countiesHiddenStyle);
                }
            }
            if (zoom >= 9) {
                if (this._countiesLabels.length && !this._countiesLabels[0].marker.map) {
                    this._countiesLabels.forEach(l => l.marker.setMap(this._map));
                }
            } else {
                if (this._countiesLabels.length && this._countiesLabels[0].marker.map) {
                    this._countiesLabels.forEach(l => l.marker.setMap(null));
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    _onCountyClick = (ev) => {
        try {
            const geometry = ev.feature.getGeometry();
            let polygon = new google.maps.Polygon();
            const latLngs = [];
            geometry.forEachLatLng(latLng => latLngs.push(latLng));
            polygon.setPath(latLngs);
            this._selectMapMarkersWithinPolygon(polygon);
        } catch (err) {
            console.log(err);
        }
    }

    async _createInfoWindow(event, content) {
        try {
            this._infoWindow.setContent(content);
            this._infoWindow.setPosition(event.latLng);
            this._infoWindow.open({ map: this._map, shouldFocus: false });
        } catch (err) {
            console.log(err);
        }
    }

    _streetViewStatusChanged() {
        if (!this._panorama || !this.streetView) return;
        try {
            const status = this._panorama.getStatus();
            if (status === google.maps.StreetViewStatus.ZERO_RESULTS || status === google.maps.StreetViewStatus.UNKNOWN_ERROR) {
                this._notifier.info('map-street-view-not-found');
            }
        } catch (err) {
            console.log(err);
        }
    }

    _setStreetViewHeading() {
        if (!this._panorama || !this.streetView) return;
        try {
            const panoramaPosition = this._panorama.getPosition().toJSON();
            const streetViewPosition = { lat: this.streetView.latitude, lng: this.streetView.longitude };
            const heading = this._getHeadingDegrees(panoramaPosition, streetViewPosition);
            this._panorama.setPov({ heading, pitch: 0 });
        } catch (err) {
            console.log(err);
        }
    }

    _getHeadingDegrees(gps1, gps2) {
        let dLon = (gps2.lng - gps1.lng) * Math.PI / 180;

        let lat1 = gps1.lat * Math.PI / 180;
        let lat2 = gps2.lat * Math.PI / 180;
    
        let y = Math.sin(dLon) * Math.cos(gps2.lat);
        let x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon);
    
        let rad = Math.atan2(y, x);
        let deg = rad * 180 / Math.PI;

        return deg * -1;
    }
}
