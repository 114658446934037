import { PLATFORM } from 'aurelia-pal';
import { RouterEvent } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { Menus } from 'services/menus';
import { I18n } from 'common/i18n';
import { Notifier } from 'common/ui';
import { Sites } from 'services/sites';
import { DialogService } from 'aurelia-dialog';
import { Nylas } from 'services/nylas';
import { Dialer, DIALER_TEAM } from 'services/dialer';
import { Drawer } from 'common/drawer';
import { AgentCardDialog } from './../members/agents/dialogs/agent-card-dialog';
import { PolicyCardDialog } from './../members/policies/dialogs/policy-card-dialog';
import { LeadCardDialog2 } from './../members/leads/dialogs/lead-card-dialog2';
import { c } from 'common/common';
import { ROLE } from 'common/constants';
import { RouterConfigBase } from 'resources/base-classes/router-config-base';
import environment from '../../config/environment.json';
PLATFORM.moduleName('./../members/agents/dialogs/agent-card-dialog');
PLATFORM.moduleName('./../members/policies/dialogs/policy-card-dialog');
PLATFORM.moduleName('./../members/leads/dialogs/lead-card-dialog2');

export class Admin extends RouterConfigBase {
    static inject = [EventAggregator, Security, Menus, I18n, Notifier, Sites, DialogService, Nylas, Dialer];
    _i18n;
    _notifier;
    _sites;
    _dialogService;
    _nylas;
    _dialer;

    pageTitle;
    pageDescription;

    adminToolsOn = false;
    _handlers = [];

    constructor(ea, security, menus, i18n, notifier, sites, dialogService, nylas, dialer) {
        super(ea, security, menus, 'admin', true)
	    this._i18n = i18n;
        this._notifier = notifier;
        this._sites = sites;
        this._dialogService = dialogService;
        this._nylas = nylas;
        this._dialer = dialer;
	}

    async activate() {
        await this._i18n.loadNamespace(['admin', 'note', 'policy', 'client', 'audit-log', 'lead']);
    }
    
    configureRouter(config, router){
        config.title = 'Admin';
        config.auth = true;
        config.map([
            {
                route: '',
                moduleId: PLATFORM.moduleName('admin/dashboard'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: this._security.accessAdminRoles(), title: 'Admin Dashboard', description: '' },
            },
            {
                route: ['team-members', 'agents'],
                name: 'admin-agents',
                moduleId: PLATFORM.moduleName('admin/team-members'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: [ROLE.Admin, ROLE.AgentAdmin], title: 'Agent Search', description: 'Search for an agent to see agent details' },
            },
            {
                route: ['team-member/:id', 'agent/:id'],
                moduleId: PLATFORM.moduleName('admin/team-member'),
                nav: false,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Agent', description: 'View and edit an agent' },
            },
            {
                route: ['team-member-hierarchy/:id', 'agent-hierarchy/:id'],
                moduleId: PLATFORM.moduleName('admin/team-member-hierarchy'),
                nav: false,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Agent Hierarchy', description: 'View an agent upline and downline' },
            },
            {
                route: 'reports',
                moduleId: PLATFORM.moduleName('admin/reports/_index'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Reports', description: 'Run reports for agents, agencies, level changes, policies and products' },
            },
            {
                route: 'policy/upload-files',
                moduleId: PLATFORM.moduleName('admin/policies/upload-files'),
                title: 'Upload Files',
                nav: false,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Upload Policy Documents', description: 'Upload individual files or a zip with multiple. File name must be the policy number.' }
            },
            {
                route: ['policy/:id?'],
                moduleId: PLATFORM.moduleName('admin/policies/edit-policy'),
                title: 'Edit Policy',
                nav: false,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Policy', description: 'Enter the policy number to view and edit a policy' }
            },
            {
                route: ['carrier/imports', 'carrier/imports/:fileName'],
                moduleId: PLATFORM.moduleName('admin/carrier/file-imports'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Carrier Imports', description: 'View carrier file uploads and automatic data feeds' },
            },
            {
                route: ['carrier/uploads', 'carrier/uploads/:fileName', 'carrier/uploads/data-feed/:exceptionId'],
                moduleId: PLATFORM.moduleName('admin/carrier/uploads'),
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: [ROLE.Admin, ROLE.PolicyReviewer, ROLE.PolicyAdmin], title: 'Carrier Uploads', description: 'Upload policy files and commission rates' },
            },
            {
                route: 'reports/policy/status-changes',
                moduleId: PLATFORM.moduleName('admin/policies/status-changes'),
                title: 'Status Changes',
                nav: true,
                membersOnly: true,
                adminOnly: false,
                settings: { roles: [ROLE.Admin, ROLE.PolicyAdmin], title: 'Policy Status Changes', description: 'Aggregation of all files processed during the selected day' }
            },
            {
                route: 'content',
                moduleId: PLATFORM.moduleName('admin/content/editor'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Content', description: 'Manage content displayed on the site' },
            },
            {
                route: 'emails',
                moduleId: PLATFORM.moduleName('admin/content/emails'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Emails', description: 'Manage emails sent by the site' },
            },
            {
                route: 'ui-terms',
                moduleId: PLATFORM.moduleName('admin/content/ui-terms'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Terms', description: 'Manage terms displayed within the site' },
            },
            {
                route: 'approvals',
                moduleId: PLATFORM.moduleName('admin/approvals/pending-approvals'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Pending Approvals', description: 'Approve or reject updates to agent site bios' },
            },
            {
                route: 'kpis',
                moduleId: PLATFORM.moduleName('admin/kpis/kpi-templates'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'KPI Templates', description: 'Manage key performance indicators' },
            },
            {
                route: 'carriers',
                moduleId: PLATFORM.moduleName('admin/carrier/carrier-list'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Carriers', description: 'Manage carriers, products and statuses' },
            },
            {
                route: 'sites',
                moduleId: PLATFORM.moduleName('admin/site/site-list'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Site Themes', description: 'Manage site themes' },
            },
            {
                route: 'landing-pages',
                moduleId: PLATFORM.moduleName('admin/landing-pages/list'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Landing Pages', description: 'Manage landing pages' },
            },
            {
                route: 'answers',
                moduleId: PLATFORM.moduleName('admin/q-and-a/answers'),
                nav: true,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Questions & Answers', description: 'Manage Q&A displayed on the personal agent sites' },
            },
            {
                route: 'answers/:slug',
                moduleId: PLATFORM.moduleName('admin/q-and-a/answer'),
                nav: false,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Answers', description: 'Manage Q&A displayed on the personal agent sites' },
            },
            {
                route: 'contests',
                moduleId: PLATFORM.moduleName('admin/contests/list'),
                nav: false,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Contests', description: 'Manage contests and assign points' },
            },
            {
                route: 'leads',
                moduleId: PLATFORM.moduleName('admin/leads/_index'),
                nav: false,
                membersOnly: true,
                adminOnly: true,
                settings: { title: 'Leads', description: 'Manage LPFN leads' },
            },
            {
                route: 'map',
                moduleId: PLATFORM.moduleName('admin/us-map'),
                nav: false,
                membersOnly: true,
                adminOnly: true,
                settings: { roles: [ROLE.Admin], title: 'Test Map', description: 'Test map functionality' },
            },
        ]);

        this.router = router;
    }

    attached() {
        this._attached();
        this._handlers.push(this._ea.subscribe(c.EventKeys.onboarding.completed, () => this._onboardingCompleted()));
        this._handlers.push(this._ea.subscribe(c.EventKeys.navigation.changing, () => this.closeMenu()));
        this.sidebarEl = document.getElementById('kt_app_sidebar');
        this._applyTheme();
        this.setMenu();
        this._initialize();
    }

    async _initialize() {
        this._handlers.push(this._ea.subscribe(RouterEvent.Complete, (event) => {
            this._resetLogoutTimer();
        }));
        this._handlers.push(this._ea.subscribe(c.EventKeys.site.openProfile, (data) => this._openProfile(data)));
        this._handlers.push(this._ea.subscribe(c.EventKeys.site.openPolicy, (data) => this._openPolicy(data)));
        this._handlers.push(this._ea.subscribe(c.EventKeys.site.openLead, (data) => this._openLead(data)));

        this.canAutoDial = await this._dialer.canDial(DIALER_TEAM.NoPrompt);

        this._resetLogoutTimer();
        this._nylas.loadScript();
    }

    detached() {
        this._detached();
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
        if (this._logoutTimer) window.clearTimeout(this._logoutTimer);
        this.closeMenu();
    }

    closeMenu() {
        if (this._sidebarDrawer) this._sidebarDrawer.hide();
    }

    _onboardingCompleted() {
        this.showSidebarMenu = true;
        this.showQuickActionsButton = true;
        this.showHeaderOptions = true;
        this._initialize();
    }

    async _applyTheme() {
        try {
            const theme = await this._sites.theme();
            this.lightLogo = `${environment.static}/${theme.light.logoPath}`;
            this.darkLogo = `${environment.static}/${theme.dark.logoPath}`;
            this.lightIcon = `${environment.static}/files/themes/${theme.key}/favicons/favicon-96x96.png`;
            this.darkIcon = `${environment.static}/files/themes/${theme.key}/favicons/favicon-96x96.png`;
        } catch (err) {
            console.log(err);
        }
    }

    _resetLogoutTimer() {
        if (this._logoutTimer) window.clearTimeout(this._logoutTimer);
        this._logoutTimer = window.setTimeout(() => {
            this._security.logout();
            this._notifier.info('inactivity-timeout-message', 'security-notice', true);
            this.router.navigate('#/');
        }, 4 * 60 * 60 * 1000); // 4 hrs = 4 * 60 * 60 * 1000
    }

    _openProfile(data) {
        const model = { id: data.member ? data.member.id : data.memberId };
        model.tab = data.tab;
	    this._dialogService.open({ viewModel: AgentCardDialog, model, ignoreTransitions: true });
    }

    _openPolicy(data) {
        const model = { id: data.policyId };
        model.tab = data.tab;
	    this._dialogService.open({ viewModel: PolicyCardDialog, model, ignoreTransitions: true });
    }

    _openLead(data) {
        const model = { id: data.lead ? data.lead.id : data.leadId };
        model.tab = data.tab;
        model.action = data.action;
        this._dialogService.open({ viewModel: LeadCardDialog2, model, ignoreTransitions: true });
    }

    openSidebar() {
        try {
            if (!this._sidebarDrawer) this._sidebarDrawer = new Drawer(this.sidebarEl);
            this._sidebarDrawer.show();
        } catch (err) {
            console.log(err);
        }
    }

    showNavigation(row) {
        if (!row.settings) return true;
        if (row.settings.adminOnly && !this.isAdmin) return false;
        if (row.settings.minimumLevel) {
            return this._security.isInMinimumLevel(row.settings.minimumLevel);
        }
        return true;
    }
}
