import { PLATFORM } from 'aurelia-pal';
import { bindable, observable, computedFrom } from 'aurelia-framework';
import { Security } from 'common/security';
import { Nylas } from 'services/nylas';
import { Calendar as CalendarSvc } from 'services/calendar';
import { Members } from 'services/members';
import { Notifier } from 'common/ui';
import { DialogService } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { YourCalendarOnlineEditor } from './dialog/your-calendar-online-editor';
import copy from 'copy-to-clipboard';
PLATFORM.moduleName('./dialog/your-calendar-online-editor');


export class Calendar {
    static inject = [Security, Nylas, CalendarSvc, Members, Notifier, DialogService, NewInstance.of(ValidationController)];

    _security;
    _nylas;
    _calendar;
    _members;
    _notifier;
    _dialogService;
    _validationController;

    @bindable memberId;
    @observable email;
    nylasAuthUrl;
    downlineUsesBookingCalendars = false;
    forMemberId;

    constructor(security, nylas, calendar, members, notifier, dialogService, validationController) {
        this._security = security;
        this._nylas = nylas;
        this._calendar = calendar;
        this._members = members;
        this._notifier = notifier;
        this._dialogService = dialogService;
		this._validationController = validationController;
		this._validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('email').required().email()
            .on(this);
    }

    async attached() {
        this._isAttached = true;
        await this._initialize();
    }

    memberIdChanged() {
        if (!this._isAttached) return;
        this._initialize();
    }

    emailChanged() {
        if (!this._initialized) return;
        if (!this.email) {
            this.nylasAuthUrl = undefined;
            return;
        }
        this._loadNylasAuthUrl();
    }

    async _initialize() {
        if (!this.memberId) return;
        try {
            if (!this._security.isAuthenticatedMember(this.memberId)) {
                // Need to load up the agent and load all calendar details for the agent
                const agent = await this._members.getMember(this.memberId);
                this.forMemberId = this.memberId;
                this.email = agent.email;
                this.canSetSchedulingPages = agent.isAgency;
            } else {
                this.email = this._security.agent.email;
                this.canSetSchedulingPages = this._security.isAgency();
            }
            this.mustReauth = await this._nylas.mustReauth(this.forMemberId);
            await this._load();
            this.nylasAuthenticated = await this._nylas.isAuthenticated(this.forMemberId);
        } catch (err) {
            console.log(err);
        } finally {
            this._initialized = true;
        }
    }

    async _load() {
        try {
            this.loading = true;
            await Promise.all[this._loadNylasAuthUrl(), this._loadYourCalendarOnline(), this._loadActions()];
        } catch (err) {
            console.log(err);
        } finally {
            this.loading = false;
        }
    }

    async _loadYourCalendarOnline() {
        try {
            this.yco = await this._calendar.yourCalendarOnline(this.forMemberId);
            console.log('YCO', this.yco);
            this.ycoPreviewQs = this.yco.isActive ? '' : '?preview=true';
        } catch (err) {
            console.log(err);
        }
    }

    async _loadNylasAuthUrl() {
        try {
            const v = await this._validationController.validate();
            if (!v.valid) return;
            this.authorizedEmail = await this._nylas.authorizedEmail(this.forMemberId);
            const email = await this._nylas.email(this.email, this.forMemberId);
            this.nylasAuthUrl = await this._nylas.redirectToAuthUrl(email, undefined, this.forMemberId);
        } catch (err) {
            console.log(err);
        }
    }

    async _loadActions() {
        try {
            if (!this.canSetSchedulingPages) return; // only agencies get this option
            this.downlineUsesBookingCalendars = this._calendar.downlineUsesBookingCalendars();
        } catch (err) {
            console.log(err);
        }
    }

    async reauthNylas() {
        try {
            await this._nylas.resetAuth(this.forMemberId);
            this.mustReauth = await this._nylas.mustReauth(this.forMemberId);
            this._loadNylasAuthUrl();
        } catch (err) {
            console.log(err);
        }
    }

    copyToClipboard(bookingCalendarId) {
        let url = this.yco.url;
        if (bookingCalendarId) url += `/booking/${encodeURIComponent(bookingCalendarId)}`;
	    copy(url);
        this._notifier.success('your-calendar-online-url-copy-success');
    }

    async openEdit() {
        this._dialogService.open({ viewModel: YourCalendarOnlineEditor, model: { memberId: this.memberId, yourCalendarOnline: this.yco }, ignoreTransitions: true }).whenClosed(response => {
            this._initialize();
        });
        return false;
    }

    async setDownlineUseBookingCalendars(checked) {
        try {
            await this._calendar.setDownlineUseBookingCalendars(checked, this.forMemberId);
        } catch (err) {
            console.log(err);
        }
    }
}