import { activationStrategy } from 'aurelia-router';
import { observable, computedFrom } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { I18n } from 'common/i18n';
import { Security } from 'common/security';
import { DIALER_TEAM, Dialer } from 'services/dialer';
import { Notifier } from 'common/ui';
import { Leads, LEAD_STATUS, LEAD_TYPE } from 'services/leads';
import { LeadStatusValueConverter } from 'resources/value-converters/lead-status';
import { LeadTypeValueConverter } from 'resources/value-converters/lead-type';
import { LeadDispositionValueConverter } from 'resources/value-converters/lead-disposition';
import { SelectDialerTeamDialog } from './dialogs/select-dialer-team-dialog';
import { DialogService } from 'aurelia-dialog';
import { Page } from 'common/ui';
import { Api } from 'common/server';
import { c } from 'common/common';
import { LpfnUtil } from 'common/utils';
import moment from 'moment';

PLATFORM.moduleName('./dialogs/select-dialer-team-dialog');

export class LeadList {
    static inject = [EventAggregator, I18n, Security, Dialer, Notifier, Leads, LeadStatusValueConverter, LeadTypeValueConverter, LeadDispositionValueConverter, DialogService, Page, Api];
    _ea;
    _i18n;
    security;
    _dialer;
    _notifier;
    _leads;
    _lsvc;
    _ltvc;
    _ldvc;
    _dialogService;
    _page;
    _api;

    includePdf = true;
    includeLeadRecord = true;

    @observable toggleSelect = false;
    dialerTeam;
    canDial = false;
    totalItems;

    filters = [
        { key: 'search', value: '', keys: ['firstName', 'lastName', 'city', 'state', 'zipCode', 'county', 'email', 'typeName', 'source', 'statusName', 'dispositionName', 'phone'] },
    ];
    filterColumns;
    pageSize = 10;
    DG_ORDERED_IDS_EVENT_KEY = 'lpfn-lead-list-data-grid-ordered-ids';
    selectedCount = 0;
    view = 'list';

    _handlers = [];

    constructor(ea, i18n, security, dialer, notifier, leads, lsvc, ltvc, ldvc, dialogService, page, api) {
        this._ea = ea;
        this._i18n = i18n;
        this.security = security;
        this._dialer = dialer;
        this._notifier = notifier;
        this._leads = leads;
        this._lsvc = lsvc;
        this._ltvc = ltvc;
        this._ldvc = ldvc;
        this._dialogService = dialogService;
        this._page = page;
        this._api = api;
    }

    determineActivationStrategy() {
        return activationStrategy.invokeLifecycle;
    }

    activate(model) {
        this._leadTypes = null;
        this._notLeadTypes = null;
        if (model.leadType === LEAD_TYPE.EmergencyContact) {
            this._leadTypes = [LEAD_TYPE.EmergencyContact];
        } else if (!model.leadType) {
            this._notLeadTypes = [LEAD_TYPE.EmergencyContact];
        }
        if (this._isAttached) {
            this._load();
        }
    }

    attached() {
        this._isAttached = true;
        this._handlers.push(this._ea.subscribe(c.EventKeys.lead.deleted, (data) => this._onLeadDeleted(data)));
        this._handlers.push(this._ea.subscribe(c.EventKeys.lead.updated, (data) => this._onLeadUpdated(data)));
        this._handlers.push(this._ea.subscribe(c.EventKeys.lead.profileUpdated, (data) => this._onLeadUpdated(data, true)));
        this._handlers.push(this._ea.subscribe(this.DG_ORDERED_IDS_EVENT_KEY, (data) => this._onDataGridOrderedIds(data)));
        this._handlers.push(this._ea.subscribe('lead-map-selected-ids-changed', (data) => this._onSelectedIdsChanged(data.ids)));
        this._load();
        this._loadCanAutoDial();
    }

    detached() {
        this._isAttached = false;
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
        this._leads.clearNav();
    }

    _onLeadDeleted() {
        this.reloadKpis = new Date().valueOf();
        this._load();
    }

    /**
     * Called when a lead is updated
     * @param {Object} data - { lead }
     */
    _onLeadUpdated(data, profileUpdated = false) {
        try {
            const lead = this.leads.find(x => x.id === data.lead.id);
            if (!lead) return;
            this.reloadKpis = new Date().valueOf();
            if (profileUpdated) {
                lead.fullName = data.lead.fullName;
                lead.firstName = data.lead.firstName;
                lead.lastName = data.lead.lastName;
                lead.city = data.lead.city;
                lead.state = data.lead.state;
                lead.zipCode = data.lead.zipCode;
                lead.county = data.lead.county;
                lead.email = data.lead.email;
                lead.phone = data.lead.phone;
                lead.lastWorkedDate = moment().toISOString();
                return;
            }

            // update the properties displayed on the list
            if (lead.disposition != data.lead.disposition) {
                lead.disposition = data.lead.disposition;
                lead.dispositionDate = data.lead.dispositionDate;
                lead.dispositionName = this._ldvc.toView(lead.disposition);
                lead.lastWorkedDate = moment().toISOString();
            }
            if (lead.status != data.lead.status) {
                lead.status = data.lead.status;
                lead.statusName = this._lsvc.toView(lead.status);
                lead.lastWorkedDate = moment().toISOString();
            }
            if (lead.doNotCall != data.lead.doNotCall) {
                lead.doNotCall = data.lead.doNotCall;
                lead.doNotCallDate = data.lead.doNotCallDate;
                lead.lastWorkedDate = moment().toISOString();
            }

            this.reapplyDataGrid = new Date().valueOf();
        } catch (err) {
            console.log(err);
        }
    }

    _onDataGridOrderedIds(orderedIds) {
        const navData = [];
        orderedIds.forEach(id => {
            const lead = this.leads.find(x => x.id === id);
            navData.push({ id, name: lead.fullName });
        });
        this._leads.setNavListWithNames(navData);
    }

    async _load() {
        try {
            this.loading = true;
            this.leads = await this._leads.forAgent(this._leadType, null, this._leadTypes, this._notLeadTypes);
            this._onDataGridOrderedIds(this.leads.map(x => x.id));
            this._initializeFilters();
        } catch (err) {
            console.log(err);
            this._leads.clearNav();
        } finally {
            this.loading = false;
        }
    }

    async _loadCanAutoDial() {
        try {
            this.dialerTeams = await this._dialer.agentTeams(this.security.authenticatedMemberId);
            this.canAutoDialLead = this.dialerTeams.includes(DIALER_TEAM.Single);
        } catch (err) {
            console.log(err);
        }
    }

    async autoDial() {
        try {
            const leadIds = this.dgLeads.filter(x => x.selected).map(x => x.id);
            if (leadIds.length === 0) return;

            const model = { dialerTeams: this.dialerTeams };
            this._dialogService.open({ viewModel: SelectDialerTeamDialog, model: model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
                const teamKey = response.output.dialerTeam;
                const dialerResponse = await this._dialer.call(leadIds, teamKey);
                if (dialerResponse.succeeded) this._notifier.success('auto-dialer-success');
                else this._notifier.error(dialerResponse.message);
            });
        } catch (err) {
            console.log(err);
            this._notifier.error('auto-dialer-error');
        }
    }

    toggleSelectChanged() {
        this.leads.forEach(l => {
            l.selected = this.toggleSelect;
        });
        this.dgLeads.forEach(l => {
            l.selected = this.toggleSelect;
        });
        this.selectedChanged();
    }

    selectedChanged() {
        this._ignoreNextSelectedIdsChangedEvent = true;
        const selectedIds = this.leads.filter(x => x.selected).map(x => x.id);
        this.canDial = selectedIds.length > 0;
        this._ea.publish('lead-list-selected-ids-changed', { ids: selectedIds });
        this.selectedCount = selectedIds.length;
    }

    clearSelected() {
        this.leads.forEach(l => l.selected = false);
        this.dgLeads.forEach(l => l.selected = false);
        this._ea.publish('lead-list-selected-ids-changed', { ids: [] });
        this.canDial = false;
        this.selectedCount = 0;
    }

    _onSelectedIdsChanged(ids) {
        this.dgLeads.forEach(l => {
            l.selected = ids.includes(l.id);
        });
        this.leads.forEach(l => {
            l.selected = ids.includes(l.id);
        });
        this.canDial = ids.length > 0;
        this.selectedCount = ids.length;
    }

    async exportLeads() {
        let exportIds = this.dgLeads.filter(x => x.selected).map(x => x.id);
        if (!exportIds.length) exportIds = this.dgLeads.map(x => x.id);
        const model = { ids: exportIds, exportType: null, assignedToAgentId: null };
        await this._page.export(this._api, 'leads/export/list', model);
    }

    async exportLeadsPdf() {
        let exportIds = this.dgLeads.filter(x => x.selected).map(x => x.id);
        if (!exportIds.length) exportIds = this.dgLeads.map(x => x.id);
        const model = { ids: exportIds, inline: true, includePdf: this.includePdf, includeLeadRecord: this.includeLeadRecord };
        await this._page.export(this._api, 'leads/export/pdf', model, true);
    }

    _initializeFilters() {
        try {
            const filterColumns = [];
            const defaultStatuses = LEAD_STATUS.defaults().map(x => this._lsvc.toView(x));
            const allStatuses = LEAD_STATUS.all().map(x => this._lsvc.toView(x));
            filterColumns.push({ key: 'statusName', labelKey: 'status', type: 'options', options: allStatuses, defaultValue: defaultStatuses.join('|') });
            filterColumns.push({ key: 'dispositionName', labelKey: 'lead:disposition', type: 'options', options: LpfnUtil.uniqueArray(this.leads.map(x => x.dispositionName)), defaultValue: undefined });
            filterColumns.push({ key: 'powerScore', labelKey: 'lead:power-score', type: 'number', defaultValue: undefined });
            filterColumns.push({ key: 'source', labelKey: 'lead:source', type: 'options', options: LpfnUtil.uniqueArray(this.leads.map(x => x.source)), defaultValue: undefined });
            filterColumns.push({ key: 'typeName', labelKey: 'lead:type', type: 'options', options: LpfnUtil.uniqueArray(this.leads.map(x => this._ltvc.toView(x.type))), defaultValue: undefined });
            filterColumns.push({ key: 'coverageAmount', labelKey: 'lead:coverage-amount', type: 'number', defaultValue: undefined });
            filterColumns.push({ key: 'city', labelKey: 'city', type: 'string', defaultValue: undefined });
            filterColumns.push({ key: 'state', labelKey: 'state', type: 'options', options: LpfnUtil.uniqueArray(this.leads.map(x => x.state)), defaultValue: undefined });
            filterColumns.push({ key: 'county', labelKey: 'county', type: 'options', options: LpfnUtil.uniqueArray(this.leads.map(x => x.county)), defaultValue: undefined });
            filterColumns.push({ key: 'zipCode', labelKey: 'zip', type: 'string', allowMultiple: true, multipleDelimiter: ',', defaultValue: undefined });
            filterColumns.push({ key: 'assignedDate', labelKey: 'lead:assigned', type: 'date', initializeValue: '>|-7', defaultValue: undefined });
            filterColumns.push({ key: 'firstName', labelKey: 'first-name', type: 'string', defaultValue: undefined });
            filterColumns.push({ key: 'lastName', labelKey: 'last-name', type: 'string', defaultValue: undefined });
            this.filterColumns = filterColumns;
        } catch (err) {
            console.log(err);
        }
    }

    toggleFilters() {
        this.showDrawer = !this.showDrawer;
    }

    /**
     * Sets all the filters used by the grid
     * Called by the filter element after it is initialized
     * @param {[Object]} filters - the filters set by the filter element
     * @returns 
     */
    filterColumnsSet(filters) {
        if (!filters) return;
        const newFilters = [];
        this.filters.forEach(f => newFilters.push(f));
        filters.forEach(f => newFilters.push(f));
        this.filters = newFilters;
    }

    gridFiltered(data) {
        this.filtered = data.filtered;
        this.filteredBy = data.filteredBy;
    }

    removeFilter(filter) {
        this.removeAdvancedFilter = filter;
    }

    statusIcon(status) {
        return LEAD_STATUS.icon(status);
    }

    onDataGridChanged(detail) {
        if (!detail || !detail.filteredData) return;
        this.filteredLeads = [];
        this.filteredLeads = detail.filteredData;
        this.countWithoutGeolocation = this.filteredLeads.filter(l => !l.geolocation).length;
    }

    @computedFrom('view', 'countWithoutGeolocation')
    get showCountWithoutGeolocation() {
        if (this.view === 'list') return false;
        return this.countWithoutGeolocation > 0;
    }

    @computedFrom('filteredLeads', 'view')
    get leadsDisplayedCount() {
        if (this.view === 'list') {
            this.countWithoutGeolocation = this.filteredLeads ? this.filteredLeads.filter(x => !x.geolocation).length : 0;
            return this.totalItems;
        }
        // For the map, display the total with geolocation unless there are any checked leads
        const checkedIds = this.leads.filter(x => x.selected).map(x => x.id);
        let total = this.totalItems;
        if (checkedIds.length) {
            total = checkedIds.length;
            this.countWithoutGeolocation = this.filteredLeads ? this.filteredLeads.filter(x => checkedIds.includes(x.id) && !x.geolocation).length : 0;
        }
        return total - this.countWithoutGeolocation;
    }

    @computedFrom('filteredLeads', 'countWithoutGeolocation', 'view')
    get leadsNotDisplayedWithoutGeolocationCount() {
        if (this.view === 'list') return this.countWithoutGeolocation;
        // For the map, display the total without geolocation unless there are any checked leads
        const checkedIds = this.leads.filter(x => x.selected).map(x => x.id);
        if (checkedIds.length) {
            return this.filteredLeads.filter(x => checkedIds.includes(x.id) && !x.geolocation).length;
        }
        return this.countWithoutGeolocation;
    }

    showView(view) {
        this.view = view;
        this.viewingMap = this.view === 'map';

        if (this.view === 'map') {
            this.loadMap = new Date().valueOf();
        }
    }

    viewLeadsFromKpi(leadIds) {
        if (leadIds === undefined) {
            this.viewingKpiLeads = false;
            this.leads = JSON.parse(JSON.stringify(this._displayedLeads));
            this._displayedLeads = undefined;
            this.restoreFilters = new Date().valueOf();
        } else {
            if (this.viewingKpiLeads) {
                // need to reset the list based on the original, not the current kpi list
                const originalLeads = JSON.parse(JSON.stringify(this._displayedLeads));
                this.leads = originalLeads.filter(x => leadIds.includes(x.id));
            } else {
                this.viewingKpiLeads = true;
                this._displayedLeads = JSON.parse(JSON.stringify(this.leads));
                this.leads = this.leads.filter(x => leadIds.includes(x.id));
                this.clearFilters = new Date().valueOf();
            }
        }
        this.reapplyDataGrid = new Date().valueOf();
    }
}
