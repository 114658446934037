import { HttpClient } from 'aurelia-http-client';
import { siteUrlBase } from 'common/common';
import environment from '../../config/environment.json';
import { Capacitor } from '@capacitor/core';

export class Versions {
    _checkVersionInMinutes = environment.versionCheckMinutes || 20;
    _lastChecked;
    _httpClient;

    version;

    constructor() {
        this.version = (typeof __lpfn_version === 'string') ? __lpfn_version : '0.0.0';

        this._lastChecked = new Date(-8640000000000000); // the minimum ms since epoch value for js date
        this._initializeHttpClient();
    }

    _initializeHttpClient() {
        if (this._httpClient) return;
        this._httpClient = new HttpClient();
    }

    async hasNewClientVersion() {
        if (Capacitor.isNativePlatform()) return false;
        const now = new Date();
        try {
            const diffTime = Math.abs(this._lastChecked.getTime() - now.getTime());
            const diffMinutes = diffTime / (1000 * 60);
            if (diffMinutes < this._checkVersionInMinutes) return false;
        } catch (errTime) {
            console.log(errTime);
            return false;
        }
        try {
            const response = await this._httpClient.get(`${siteUrlBase()}/version.js?v=${now.getTime()}`);
            // eslint-disable-next-line no-useless-escape
            const serverVersionMatch = response.content.match(/__lpfn_version\s\=\s"([^\s]*)"/);
            if (!serverVersionMatch) {
                return false;
            }
            const serverVersion = serverVersionMatch[1];
            return serverVersion !== this.version;
        } catch (err) {
            console.log('Version', err);
            return false;
        } finally {
            // Reset the last checked so the app will only check based on the check version in minutes
            this._lastChecked = now;
        }
    }
}
